/* Auth: Gilson Santos */

/* Project: Gestao de Precificacao */

/* Layout: Custom Bootstrap */

/* ROOT */

/* IMPORT ANGULAR.json -> "node_modules/bootstrap/dist/css/bootstrap.min.css", */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #11EEAA;
  --primary-hover: #11DDAA;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: 'Montserrat',
    sans-serif;
}

/* ----- BODY ----- */

* {
  transition: all 70ms ease-in-out;
  margin: 0;
  padding: 0;
}

*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  color: -internal-root-color;
}

body {
  height: 100%;
}

body,
p,
ul,
li,
h1,
h2,
h3,
blockquote {
  margin: 0px;
  padding: 0px;
}

/* ----- END - BODY ----- */

/* ----- LOGIN ----- */

.login-wrapper {
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.login-wrapper>.login-form {
  user-select: none;
  flex: 1;
  height: 100%;
  background: #ffffff;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -4px 0 8px rgba(0, 0, 0, .04);
}

.login-wrapper>.login-form>div {
  max-width: 420px;
  border-radius: 8px;
  border: none;
}

.login-wrapper>.login-form>div .label-pass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

  .login-wrapper > .login-form > div .label-pass > a {
    font-size: 1rem;
    color: #015A2C;
    text-decoration: none;
    font-weight: 600;
  }

.login-wrapper>.login-form>div .label-pass>a:hover {
  color: var(--primary-hover);
}

  .login-wrapper > .login-view {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    background-size: cover;
    object-fit: cover;
    background-image: url('../assets/img/photo-1499529112087-3cb3b73cec95.jpeg');
  }

.login-wrapper>.login-view::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(-45deg, var(--primary) 50%, var(--dark) 100%);
  opacity: .3;
  z-index: 0;
}

    .login-wrapper > .login-view > .wrapper-logo {
      margin-top: 96px;
      z-index: 1;
      padding: 24px;
      display: flex;
      flex-direction: column;
      user-select: none;
      color: var(--white);
      background: rgba(0, 0, 0, 0.08);
      border-radius: 6px;
    }

.login-wrapper>.login-view>footer {
  color: var(--white);
  font-weight: 600;
  z-index: 1;
}

.login-wrapper>.login-view>.wrapper-hero:hover {
  background: rgba(0, 0, 0, .4);
}

.login-wrapper>.login-view>.wrapper-hero h1 {
  font-size: 2.75rem;
  font-weight: 700;
}

.login-wrapper>.login-view>.wrapper-hero p {
  font-size: 1.75rem;
  font-weight: 400;
}

.login-wrapper>.login-form>div .logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.login-wrapper>.login-form>div .logo img {
  border-radius: 4;

  max-height: 180px;
  margin-bottom: 32px;
}

@media (max-width:1380px) {
  .login-wrapper>.login-form {
    flex: 1;
  }

  .login-wrapper>.login-view>.wrapper-hero {
    max-width: 100%;
    margin-top: 0;
  }
}

@media (max-width:800px) {
  .login-wrapper {
    flex-direction: column;
  }

  .login-wrapper>.login-form {
    flex: 3;
    width: 100%;
    padding: 16px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, .04);
  }

  .login-wrapper>.login-view {
    flex: 1;
    padding: 16px;
    justify-content: center;
  }

  .login-wrapper>.login-view>.wrapper-hero {
    width: 100%;
    margin-top: 0;
  }

  .login-wrapper>.login-view>.wrapper-hero h1 {
    font-size: 1.75rem;
  }

  .login-wrapper>.login-view>.wrapper-hero p {
    font-size: 1rem;
  }

  .login-wrapper>.login-view>footer {
    display: none;
  }
}

/* ----- END LOGIN ----- */


.gp-section .nav.nav-tabs {
  border: none !important;
}

.gp-section .nav-tabs .nav-item {
  margin-right: 16px;
}

.gp-section .nav-tabs .nav-link {
  border-bottom-width: 1px;
  border-radius: 25px;
  color: var(--dark);
  border-color: var(--gray);
}

.gp-section .nav-tabs .nav-item.show .nav-link,
.gp-section .nav-tabs .nav-link.active {
  border-color: var(--primary);
  border-width: 2px;
  color: var(--primary);
  font-weight: 500;
}

main>.breadcrumb h3 {
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}


.table-gp{
  overflow-x: auto;
  width: 100%;
  margin-bottom:16px;
}

.table-gp header{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding:8px 16px;
  margin-bottom:8px;
}
.table-gp>table{
  position: relative;
  table-layout: fixed;
  width:100%;
  border-collapse: separate;
  table-layout: fixed;
  overflow-y: auto;
  border-spacing: 0 4px;
  vertical-align: middle !important;
}

.table-gp table td,
.table-gp table th{
  border:none;
  vertical-align: middle !important;
}

.table-gp tbody tr{
  vertical-align: middle !important;
}

.table-gp tbody tr td{
  border:none;
  padding: 4px 8px;
  height: 60px;
  margin-right:0;
  text-align: left;
  vertical-align: middle !important;
  border-top:1px solid #efefef;
  border-bottom:1px solid #efefef;
}

.table-gp tbody tr:nth-of-type(odd){
  background:rgba(0, 0, 0, .03);
}

.table-gp tbody tr td:first-child {
  border-radius:4px 0 0 4px;
  border-left:1px solid #efefef;
}
.table-gp tbody tr td:last-child {
  border-radius: 0 4px 4px 0;
  border-right:1px solid #efefef;
}

@media (max-width:1080px) {
  .table-gp {
    overflow-x: auto;
    width: 100%;
  }
  .table-gp table {
    width: auto;
  }

  .table-gp::-webkit-scrollbar {
    width: 8px;
  }

  .table-gp::-webkit-scrollbar-thumb {
    width: 8px;
    height: 4px;
    background: var(--primary-hover);
  }
}


/* 
.table-gp thead{
  min-height:24px;
  font-size:14px;
  font-weight: 600;
  color:var(--dark);
  display: grid;
  grid-template-columns: minmax(12 * 1fr);
}

.table-gp thead tr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border:none;
  position: sticky;
  top:0;
}

.table-gp thead tr th{
  min-width: 80px;
  max-width: 160px;
  border:none;
}

.table-gp tbody {
  position: relative;
  overflow-y: auto;
  height: 560px;
  display: grid;
  grid-template-columns: minmax(12 * 1fr);
  grid-gap: 4px;
}

.table-gp tbody::-webkit-scrollbar {
  width: 8px;
}
.table-gp tbody::-webkit-scrollbar-thumb{
  width: 8px;
  background: var(--primary-hover);
}


.table-gp tbody tr {
  border-radius:6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height:60px;
  border:none;
  background:#fff;
  border:1px solid #efefef;
}
.table-gp tbody tr td{
  min-width: 80px;
  max-width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:none;
} */



